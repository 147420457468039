import React from "react";
import { Col, Row } from "react-bootstrap";

interface ModalInputWrapperProps {
  label: string;
  description: string;
  /**
   * if true texts and input take both full width, so that the input is below the texts
   */
  fullWidth?: boolean;
  children: React.ReactNode;
  hiddenDescription?: boolean;
}
export default function ModalInputWrapper(props: ModalInputWrapperProps) {
  return (
    <Row className="gx-0 mr-0 cms-modal-input-wrapper">
      <Col xs={props.fullWidth ? 12 : 7}>
        <Row>
          <Col>
            <div className="d-flex">
              <label className="cms-modal-label">
                {props.label}
                {/* TODO missing TooltipWrapper */}
              </label>
            </div>
          </Col>
        </Row>
        {props.hiddenDescription ? null : (
          <Row>
            <Col>
              <div className="cms-modal-text">{props.description}</div>
            </Col>
          </Row>
        )}
      </Col>
      <Col xs={props.fullWidth ? 12 : 5}>
        <div className="cms-modal-input h-100">{props.children}</div>
      </Col>
    </Row>
  );
}
